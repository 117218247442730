table {
  border: none;
}
.trhHead td {
  padding: 8px 10px;
  font-size: 12px;
  border: 1px solid #ebeef5;
}
.contentStyle {
  width: auto;
}
.courseName {
  width: 100px !important;
}
 /deep/ .el-descriptions-item__cell {
  text-align: center;
}
 /deep/ .labelStyle {
  width: 56px !important;
}
